
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

function isLocalStorageNameSupported(): boolean {
    var testKey = 'test', storage = window.localStorage;
    try {
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  }

@Injectable()
//Intercept all HTTP requests and add the anonymous user id header
export class AnonymousUserIdInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler) {

        // If local storage is not supported, just pass the request
        if (!isLocalStorageNameSupported()) {
            return next.handle(req);
        }

        // If the request is not for the API, just pass the request
        if (!req.url.startsWith(environment.apiUrl)) {
            return next.handle(req);
        }
        // Retrieve the UUID from local storage or generate a new one if it doesn't exist
        const anonymousUserId = window.localStorage.getItem('anonymousUserId') || (() => {
            const newId = uuidv4();
            window.localStorage.setItem('anonymousUserId', newId);
            return newId;
        })();

        // Clone the request and append the "Anonymous-User-Id" header
        const authReq = req.clone({
            headers: req.headers.append('Anonymous-User-Id', anonymousUserId)
        });
        // send cloned request with header to the next handler.
        return next.handle(authReq);
    }
    
}
