import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-container',
  templateUrl: './layout-container.component.html'
})
export class LayoutContainerComponent implements OnInit {




  ngOnInit() {

  }
}
