import { Injectable } from '@angular/core';
import { OAuthService, NullValidationHandler, AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

import { User } from '../models/auth.models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;
    
    constructor( private oauthService: OAuthService) {
        this.configure();
    }

    authConfig: AuthConfig = {
        issuer: environment.keycloakUrl,
        redirectUri: window.location.protocol + "//" + window.location.host + "/lessons/main",
        clientId: 'kids-app',
        scope: 'openid profile email offline_access',
        responseType: 'code',
        requireHttps: false,
        // at_hash is not present in JWT token
        disableAtHashCheck: true,
        showDebugInformation: true
      }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        if (!this.user) {
            const identyClaim = <any>this.oauthService.getIdentityClaims();
            if (identyClaim) {
                this.user = {
                    avatar: 'assets/images/users/avatar-1.jpg', 
                    name: identyClaim.name, 
                    firstName: identyClaim.given_name, 
                    lastName: identyClaim.family_name,
                    username: identyClaim.preferred_username,
                }    
            } 
        }
        return this.user;
    }

    /**
     * Performs the auth
     */
    login() {
        this.oauthService.initLoginFlow();        
    }

    async checkLoginSession() : Promise<boolean> {
        const loggedIn = await this.oauthService.loadDiscoveryDocumentAndTryLogin();
        const hasValidId = this.oauthService.hasValidIdToken();
        const hasValidAccess = this.oauthService.hasValidAccessToken();
        console.log('check login:', loggedIn, hasValidId, hasValidAccess);
        if (!hasValidId || !hasValidAccess) {
            return false;
        } else {
            return true;
        }
    }

    logout() {
        this.oauthService.logOut();
    }

    whoami() {
        console.log(this.oauthService);
        console.log('idToken', this.oauthService.hasValidIdToken());
        console.log('accessToken', this.oauthService.hasValidAccessToken());
    }

    private configure() {
        this.oauthService.configure(this.authConfig);
        this.oauthService.tokenValidationHandler = new  NullValidationHandler();
        this.oauthService.events.subscribe(e => console.log(e));
        this.oauthService.setupAutomaticSilentRefresh();
    }
}

