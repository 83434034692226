import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const baseUrl = environment.apiUrl + '/kids/report';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }


  reportProgress(sessionId: string, report: any): Promise<any> {
    return this.http.post(`${baseUrl}/progress/${sessionId}`, report).toPromise();
  }
  
  reportFinish(sessionId: string, report: any): Promise<any> {
    return this.http.post(`${baseUrl}/finish/${sessionId}`, report).toPromise();
  }
}
