import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { LayoutContainerComponent } from './layout-container.component';

import { KidsLayoutModule } from './kids/kids.module';

@NgModule({
  declarations: [LayoutContainerComponent],
  imports: [
    CommonModule,
    RouterModule,
    KidsLayoutModule
  ]
})
export class LayoutsModule { }
