<!-- Navigation -->
    <div class="container fixed-bottom mb-0 mt-0 bg-sand pt-2 pb-0" *ngIf="!isPlaygroundActive()">
        <div class="row grandstand">
            <div class="col">
                <a *ngIf="isLoggedIn()" href="/lessons/main">Home</a>
                <a *ngIf="!isLoggedIn()" href="#">Home</a>        
            </div>
            <div class="col">
                <p class="text-right mb-1">
                <!-- <a *ngIf="isLoggedIn()" (click)="logout()">Uitloggen</a> 
                <a *ngIf="!isLoggedIn()" (click)="login()">Inloggen</a> -->
                <!-- -  -->
                <a [routerLink]="['/info/contact']">Contact</a>
                </p>
            </div>
        </div>
    </div>

    <div class="container fixed-bottom mb-0 mt-0 bg-sand pt-2 pb-0" *ngIf="isPlaygroundActive()">
        <div class="row grandstand">
            <div class="col">
                <a (click)="stopLesson()">Stop</a>
            </div>
            <div class="col">
                <p class="text-right mb-1">
                    <a (click)="replayInstruction()">Uitleg</a>
                    - <a [routerLink]="['/info/contact']">Contact</a>
                </p>
            </div>
        </div>
    </div>
  <!-- Page Content -->
<router-outlet></router-outlet>
