import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const baseUrl = environment.apiUrl + '/kids/lesson';

@Injectable({
  providedIn: 'root'
})
export class LessonService {

  constructor(private http: HttpClient) { }

  getAllAdventure(): Promise<any> {
    return this.http.get(`${baseUrl}/adventure`).toPromise();
  }

  getAllPractice(): Promise<any> {
    return this.http.get(`${baseUrl}/practice`).toPromise();
  }

  getAllByCategory(categoryId): Promise<any> {
    return this.http.get(`${baseUrl}?category=${categoryId}`).toPromise();
  }

  getAllByMainCategory(categoryId): Promise<any> {
    return this.http.get(`${baseUrl}?maincategory=${categoryId}`).toPromise();
  }

  get(id): Promise<any> {
    return this.http.get(`${baseUrl}/${id}`).toPromise();
  }
}
