import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import * as Sentry from '@sentry/angular';
// import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

// Sentry.init({
//   environment: environment.environmentName,
//   dsn: "https://35fa616b0e30477bb87dde798d913d6a@o513122.ingest.sentry.io/5696365",
//   integrations: [
//     new Integrations.BrowserTracing({
//       tracingOrigins: ['localhost:5000', 'https://yourserver.io/api'],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//   ],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
