import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/models/auth.models';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { PlaygroundService } from 'src/app/services/playground.service';


@Component({
  selector: 'app-kids-layout',
  templateUrl: './layout.component.html',
})
export class LayoutComponent implements OnInit {

  loggedInUser: User;

  constructor(
    private authService: AuthenticationService,
    private playgroundService: PlaygroundService,
  ) { }

  ngOnInit() {
    // logged in user
    this.authService.checkLoginSession().then(result => {
      this.loggedInUser = this.authService.currentUser();
    })
  }

  isLoggedIn() {
    return this.loggedInUser != undefined;
  }

  logout() {
    this.authService.logout();
  }

  login() {
    this.authService.login();
  }

  public isPlaygroundActive(): boolean {
    return this.playgroundService.isActive();
  }

  public replayInstruction(): void {
    this.playgroundService.readInstruction();
  }

  public stopLesson(): void {
    this.playgroundService.exitLesson();
  }
}
