import { Injectable } from '@angular/core';
import { LessonService } from './lesson.service';
import * as Playground from '@digidier/playground';
import { ReportService } from './report.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactModel } from '../models/contact.model';

@Injectable({
  providedIn: 'root'
})
export class PlaygroundService {

  sessionId: string;
  lessonData: any;
  playground: any;
  reportSubscription: any;
  exitSubscription: any;
  private lessonActive = false;
  

  constructor(
    private lessonService: LessonService,
    private reportService: ReportService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  public isActive(): boolean {
    return this.lessonActive
  }

  public readInstruction(): void {
    if(this.lessonActive) {
      this.playground.readInstructionEvent.instance.emit();
    }
  }

  async loadLesson(lessonId, targetArea): Promise<any> {
    this.stopLesson();//stop active lesson if applicable
    this.lessonService.get(lessonId).then(lesson => {
      this.sessionId = lesson.sessionId;
      this.lessonData = lesson;
      this.playground = (<any> Playground);
      this.playground.init(targetArea, lesson);
      this.playground.createEvent.instance.subscribe(() => {
        this.lessonActive = true;
      });

      this.exitSubscription = this.playground.exitEvent.instance.subscribe(() => {     
        this.exitLessonCallback();
      });
      this.reportSubscription =  this.playground.reportEvent.instance.subscribe((reportData) => {
        return this.report(reportData);
      });
    });
  }

  private report(reportData: any):any {
    return this.reportService.reportFinish(this.sessionId, reportData);
  }

  private exitLessonCallback(): void {
    this.playground.reportEvent.instance.unsubscribe(this.reportSubscription);
    this.playground.exitEvent.instance.unsubscribe(this.exitSubscription);
    this.lessonActive = false;
    this.router.navigate(['/lessons/previous'], {relativeTo: this.route});
  }

  //Exit exits the lesson en redirects to home (default)
  public exitLesson(): void {
    this.playground.stop();
  }

  //Stop stops lesson no further action
  public stopLesson(): void {
    if(this.playground){
      console.log("stop active lesson");
      this.playground.reportEvent.instance.unsubscribe(this.reportSubscription);
      this.playground.exitEvent.instance.unsubscribe(this.exitSubscription);
      this.playground.stop();  
    }
  }

  public getLessonState(): ContactModel {
      var data:ContactModel = {};
      data.lessonId = this.lessonData?.id;
      data.lessonData = this.lessonData;
      data.lessonReport = this.playground.getProgress();
      
      return data;
  }
}
